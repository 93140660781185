import {
  DeliveryVehiclesConfirmation,
  DeliveryVehiclesForm,
  InternalStepProgress,
  PageWithIntro,
  WizardLayout,
} from 'components';
import {
  FORM_NAME,
  createQueriesHook as createVehicleQueries,
} from '../../../utilities/vehicles';
import {
  allState as allSkipState,
  createSkippableSectionBuilderForKey,
  vehiclesSkipKeyBuilder,
} from '../../../utilities/skippedEntries';
import {
  deleteFormElement,
  getFormState,
  handleFormSubmit,
} from '../../../utilities/formstate';
import React from 'react';
import { WistiaVideo } from 'components/widgets/WistiaVideo';
import { createQueriesHook as createAllQueries } from '../../../utilities/allQueries';
import { deliveryVehiclesStep } from '../../../utilities/stepDefinitions';
import { getLicenses } from '../../../utilities/licenses';
import { stepIsComplete } from '../../../utilities/steps';
import { useTracking } from '../../../utilities/hooks';

const DeliveryVehiclesPage = () => {
  const { licenses } = getLicenses();
  const formState = getFormState(FORM_NAME);
  const introInformation = getFormState('introInformation');
  const stepCompleted = stepIsComplete(deliveryVehiclesStep);
  const submitData = handleFormSubmit(formState);
  const vehicleQueries = createVehicleQueries();
  const allQueries = createAllQueries();
  const { skippingVehicles } = allQueries;
  const { skipState } = allSkipState();

  const skippableSectionBuilder = createSkippableSectionBuilderForKey({
    label: 'I have no vehicles',
    keyBuilder: vehiclesSkipKeyBuilder,
    skipState,
    canSkip: allQueries.canSkipVehicles,
  });

  useTracking('DeliveryVehicles');

  function deleteVehicle(id) {
    deleteFormElement(formState, id);
  }

  return (
    <PageWithIntro formName={FORM_NAME} step={deliveryVehiclesStep}>
      <WizardLayout text className="delivery-vehicles">
        <h1>Add Vehicle Details</h1>
        <WistiaVideo id={deliveryVehiclesStep.intro.videoID} />

        <InternalStepProgress
          stepsConfig={[
            {
              step: 1,
              label: '1. Add Vehicle Details',
              component: (
                <DeliveryVehiclesForm
                  skippableSectionBuilder={skippableSectionBuilder}
                  skippingVehicles={skippingVehicles}
                  licenses={licenses}
                  initialValues={formState.values}
                  introInformation={introInformation.values}
                  working={formState.working || false}
                  onSubmit={submitData}
                />
              ),
            },
            {
              step: 2,
              label: '2. Confirm Vehicle Details',
              component: (
                <DeliveryVehiclesConfirmation
                  canDeleteVehicle={allQueries.canDeleteVehicle}
                  skippableSectionBuilder={skippableSectionBuilder}
                  stepCompleted={stepCompleted}
                  licenses={licenses}
                  deleteVehicle={deleteVehicle}
                  initialValues={vehicleQueries.allVehicles}
                  introInformation={introInformation.values}
                  working={formState.working || false}
                  onSubmit={handleFormSubmit(formState)}
                />
              ),
            },
          ]}
        />
      </WizardLayout>
    </PageWithIntro>
  );
};

export default DeliveryVehiclesPage;
